import { Celebration } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/material';
import CustomNavTabs from 'components/shared/CustomNavTabs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../redux/slice/auth.slice';
import { subscriptionActions } from '../../../redux/slice/subscription.slice';
import { GET_SUBSCRIPTION_PLANS } from '../../../redux/types/subscription.types';
import text from "../../shared/css/text.module.scss";
import { AntSwitch, pricingNavItems } from './helper';
import OnPremPricingCard from './OnPremPricing/OnPremPricingCard';
import styles from './pricingPlan.module.scss';
import PricingPlanCard from './PricingPlanCard';
import PricingSkeleton from './PricingSkeleton';
import { dummyOnPremisesData } from './OnPremPricing/helper';

const PricingBody = ({ handleSubmitPlan, handleChangeIsYearly, isYearly }) => {
    const dispatch = useDispatch()
    const currentUser = useSelector(authActions.selectors.getCurrentUser);

    useEffect(() => {
        const payload = {
            orgId: currentUser?.orgId
        }
        dispatch({ type: GET_SUBSCRIPTION_PLANS, payload })
    }, [currentUser?.orgId, dispatch]);

    const subscriptionPlans = useSelector(subscriptionActions.selectors.getSubscriptionPlans)
    const subscriptionDiscount = useSelector(subscriptionActions.selectors.getSubscriptionDiscount)
    const isTrailAvailable = useSelector(subscriptionActions.selectors.getIsTrailAvailable)

    const handleSwitchChange = (event) => {
        handleChangeIsYearly(event.target.checked);
    };

    const [selectedTab, setSelectedTab] = useState(0);
    const handleSelectedTab = (value) => {
        setSelectedTab(value);
    };

    const handleSubmitContact = () => {
        window.open("https://bdata.ca/contactus", "_blank");
    };

    return (
        <div className={styles.pricingBody}>
            <Typography className={text.heading1} color="primary">Plans & Pricing</Typography>
            <CustomNavTabs
                selectedTab={selectedTab}
                handleSelectedTab={handleSelectedTab}
                navItems={pricingNavItems}
                marginBottom={0}
            />
            {selectedTab === 0 &&
                <>
                    <Typography className={styles.mainTitle}>Cloud-Based Subscription Pricing (SaaS)</Typography>
                    <Typography className={text.heading2}>Cloud-based subscription pricing is designed for companies that want <b> scalability, minimal maintenance, and continuous updates.</b></Typography>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                        <Typography style={{ color: !isYearly ? 'inherit' : 'gray' }}>Billed Monthly</Typography>
                        <AntSwitch
                            checked={isYearly}
                            onChange={handleSwitchChange}
                            inputProps={{ 'aria-label': 'ant design' }}
                        />
                        <Typography style={{ color: isYearly ? 'inherit' : 'gray' }}>Billed Yearly (Save 20%)</Typography>
                    </Stack>
                    {subscriptionDiscount?.discountPercentOff > 0 && (
                        <Stack direction="row" alignItems="center" spacing={1} className={styles.discountMessage}>
                            <Celebration color="success" />
                            <Typography>
                                You got <b>{subscriptionDiscount?.discountPercentOff}%</b> discount for this payment!
                            </Typography>
                        </Stack>
                    )}

                    <Grid container item xs={12} mt={1} spacing={2} px={1} justifyContent={"space-between"} alignItems={"center"}>
                        {subscriptionPlans?.length ?
                            <>
                                {subscriptionPlans.map((plan, index) => (
                                    <Grid item key={index} xs={12} md={3}>
                                        <PricingPlanCard isTrialAvailable={isTrailAvailable && plan?.name === "Basic Cloud"} isCustomPrice={plan?.name === "Custom Cloud"} plan={plan} isYearly={isYearly} discountPercent={subscriptionDiscount?.discountPercentOff} handleSubmitPlan={handleSubmitPlan} />
                                    </Grid>
                                ))}
                            </> :
                            <>
                                {[...Array(4)].map((_, index) => (
                                    <Grid item key={index} xs={12} md={3}>
                                        <PricingSkeleton />
                                    </Grid>
                                ))}
                            </>
                        }

                    </Grid>
                </>
            }
            {selectedTab === 1 &&
                <>
                    <Typography className={styles.mainTitle}>On-Premises Pricing (Perpetual License)</Typography>
                    <Typography px={10} textAlign={"center"} className={text.heading2}>
                        For organizations needing <b>on-prem data sovereignty, compliance, and high-performance security,</b> the <b>one-time license model</b> is paired with <b>annual support & updates (25% per year).</b>
                    </Typography>
                    <Grid container item xs={12} mt={1} spacing={2} px={1} justifyContent={"space-between"} alignItems={"center"}>
                        {dummyOnPremisesData?.length ?
                            <Grid container justifyContent={"center"}>
                                {dummyOnPremisesData.map((plan, index) => (
                                    <Grid item key={index} xs={12} md={5}>
                                        <OnPremPricingCard plan={plan} handleSubmitPlan={handleSubmitContact} isNegotiable={plan?.annualSupport === "Negotiable"} />
                                    </Grid>
                                ))}
                            </Grid> :
                            <>
                                {[...Array(4)].map((_, index) => (
                                    <Grid item key={index} xs={12} md={3}>
                                        <PricingSkeleton />
                                    </Grid>
                                ))}
                            </>
                        }

                    </Grid>
                </>
            }


            <br />

        </div >
    );
};

export default PricingBody;
